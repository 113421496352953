import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Group,
  Overlay,
  Radio,
  ScrollArea,
  SegmentedControl,
  Switch,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { Fragment, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { forwardRef } from 'react';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher, getter } from '../../app/feathersClient';
import {
  getFixtureCompositeName,
  getIssueTicketCompositeName,
  getPositionCompositeName,
} from '../../common/commonResolvers';
import useMatchMutate from '../../hooks/useMatchMutate';
import DataAutocomplete from '../DataAutocomplete/DataAutocomplete';
import FullTextSearch from '../FullTextSearch/FullTextSearch';
import PositionBadgeRenderer from '../positions/PositionBadgeRenderer';
import StatusBadgeRenderer from '../statusTypes/StatusBadgeRenderer';

function LogForm({
  target,
  secondaryTarget: initialFixture,
  onAbort,
  onClose,
  onSubmit,
  error,
  success,
  ...rest
}) {
  const [fixtureId, setFixtureId] = useState(initialFixture?.id || null);
  const { venueId } = useParams();

  // **************
  //  Data Loaders
  // **************
  const { data: fixture } = useSWR(fixtureId ? `fixtures/${fixtureId}` : false, getter);
  const { data: venue } = useSWR(`venues/${venueId}`, getter);
  const { data: venueUsers } = useSWR(
    venue?.isKiosk
      ? {
          service: 'venue-access',
          query: {
            orgId: venue.orgId,
            venueId: venue.id,
          },
        }
      : false,
    fetcher
  );
  const { data: fixtureType } = useSWR(
    fixture?.typeId ? `fixture-types/${fixture.typeId}` : false,
    getter
  );
  const { data: issues } = useSWR(
    fixture?.id
      ? {
          service: 'issues',
          query: {
            fixtureId: fixture.id,
            closed: false,
            orgId: fixture.orgId,
          },
        }
      : false,
    fetcher
  );
  const { data: serviceTags } = useSWR(
    fixture?.id
      ? {
          service: 'service-tags',
          query: {
            orgId: fixture.orgId,
            $limit: 100,
          },
        }
      : false,
    fetcher
  );
  const mutate = useMatchMutate();

  // *******
  //  State
  // *******
  // Log Data State
  const [logType, setLogType] = useState('1');
  const [positionId, setPositionId] = useState(fixture?.positionId || null);
  const [triggerReset, setTriggerReset] = useState(false);
  const [triggerReplaceReset, setTriggerReplaceReset] = useState(false);
  const [repFixtureId, setRepFixtureId] = useState(null);
  const [status, setStatus] = useState(fixture?.status?.toString() || '-1');
  const [ticket, setTicket] = useState({
    context: 'none',
    ticketId: null,
    closeTicket: false,
    issueTypeId: null,
    issueType: null,
    newIssueTitle: '',
    newIssueDescription: '',
  });
  const [tags, setTags] = useState([]);
  const [user, setUser] = useState(false);
  const [suppressClose, setSuppressClose] = useState(false);
  const [positionContext, setPositionContext] = useState('-1');
  const [positionSearchExact, setPositionSearchExact] = useState(false);

  const [logTitle, setLogTitle] = useState('');
  const [logDescription, setLogDescription] = useState('');
  const autocompleteRef = useRef(null);

  // Form State
  const hasIssueType = ticket.issueTypeId ? true : false;
  const hasIssueDetails = ticket.newIssueTitle ? true : false;
  const [logTitlePristine, setLogTitlePristine] = useState(true);

  // Auto populate the log title if new ticket from issue type
  useEffect(() => {
    setLogTitle(ticket.issueType?.name || '');
    setLogDescription(ticket.issueType?.description || '');
    setTicket({
      ...ticket,
      newIssueTitle: '',
      newIssueDescription: '',
    });
    // eslint-disable-next-line
  }, [ticket.issueType]);

  useEffect(() => {
    if (success) {
      mutate(new RegExp(`fixtures/${fixture.id}`));
      if (ticket.context !== 'none') {
        mutate(new RegExp(`#service:"issues".*fixtureId:"${fixture.id}"`));
      }
      if (!suppressClose) onClose();
      if (suppressClose) {
        showNotification({
          color: 'green',
          title: 'Log Saved',
          message: 'Log saved successfully',
        });
      }
    }
    // eslint-disable-next-line
  }, [success]);

  // Update positionId and if fixture changes
  useEffect(() => {
    if (!fixtureId) return;
    if (!initialFixture && positionContext === '-1') {
      setPositionId(fixture?.positionId || null);
    }
    setTicket({
      ...ticket,
      ticketId: null,
      closeTicket: false,
      newIssueTitle: '',
      newIssueDescription: '',
    });
    setTriggerReset(true);
    // eslint-disable-next-line
  }, [fixture]);

  // If positionContext changes to -1 and we have a fixture, set positionId to fixture.positionId
  useEffect(() => {
    if (positionContext === '-1' && fixture) {
      setPositionId(fixture.positionId);
    }
    // eslint-disable-next-line
  }, [positionContext]);

  // SUBMIT
  const handleSubmit = () => {
    const data = {
      fixtureId: fixture.id,
      logType: parseInt(logType),
      status: status === '-1' ? parseInt(fixture.status) : parseInt(status),
      title: logTitle,
      description: logDescription,
    };
    // Ensure we properly compare unassigned fixtures
    const logPositionId = positionId === '' ? null : positionId;
    if (logPositionId !== fixture.positionId) {
      data.newPositionId = logPositionId;
    }
    if (ticket.ticketId && ticket.context === 'ticket') {
      data.issueId = ticket.ticketId;
      data.issueClosed = ticket.closeTicket;
    }
    if (ticket.issueTypeId && ticket.context === 'new') {
      data.issueTypeId = ticket.issueTypeId;
    }
    if (ticket.newIssueTitle && ticket.context === 'new') {
      data.issueTypeId = 'new';
      data.issueTitle = ticket.newIssueTitle;
      data.issueDescription = ticket.newIssueDescription;
    }
    if (repFixtureId && logPositionId !== fixture.positionId && fixture.positionId !== null) {
      data.replacementFixtureId = repFixtureId;
    }
    if (tags.length > 0) {
      data.serviceTags = tags;
    }
    if (user && venue?.isKiosk) {
      data.impersonate = user;
    }
    onSubmit(data, {});

    // Reset repFixtureId
    setRepFixtureId(null);
    setTriggerReplaceReset(true);
  };

  // ***************
  //  Form Validity
  // ***************
  const formIsValid = () => {
    if (!logType) return false;
    if (!logTitle) return false;
    if (ticket.context === 'ticket' && !ticket.ticketId) return false;
    if (ticket.context === 'new' && !hasIssueType && !hasIssueDetails) return false;
    if (venue?.isKiosk && !user) return false;
    return true;
  };

  // Open Ticket Section
  const OpenTicket = (
    <Collapse in={ticket.context === 'ticket'}>
      <Text size='sm' weight='500' mt='md'>
        Attach to Open Ticket
      </Text>
      <Text size='xs' color='dimmed'>
        Select from currently open tickets on this fixture
      </Text>

      <ScrollArea.Autosize maxHeight={150} type='auto'>
        <Radio.Group
          orientation='vertical'
          value={ticket.ticketId}
          onChange={(val) => setTicket({ ...ticket, ticketId: val })}
        >
          {issues?.data &&
            issues.data.map((issue) => (
              <Radio key={issue.id} value={issue.id} label={getIssueTicketCompositeName(issue)} />
            ))}
        </Radio.Group>
      </ScrollArea.Autosize>

      <Text size='sm' weight='500' mt='md'>
        Ticket Status
      </Text>
      <Text size='xs' color='dimmed'>
        Leave ticket unresolved or mark as closed
      </Text>

      <Switch
        mt='xs'
        label='Close ticket?'
        checked={ticket.closeTicket}
        onChange={(e) => setTicket({ ...ticket, closeTicket: e.target.checked })}
      ></Switch>
    </Collapse>
  );

  // Create Ticket Section
  const CreateTicket = (
    <Collapse in={ticket.context === 'new'}>
      <Collapse in={!hasIssueDetails}>
        <DataAutocomplete
          disabled={hasIssueDetails}
          label={'New Ticket from Fixture Issue'}
          model={{
            service: 'issue-types',
            injectGetQuery: ['orgId'],
          }}
          placeholder='Select from existing fixture type issues'
          mt='md'
          value={ticket.issueTypeId}
          onChange={(val) => {}}
          onChangeFullData={(data) =>
            setTicket({ ...ticket, issueTypeId: data?.id || null, issueType: data })
          }
          passRef={autocompleteRef}
          matchField='name'
          additionalQuery={{
            fixtureTypeIds: {
              $contains: JSON.stringify(fixture?.typeId),
            },
          }}
          getValueMap={(item) => ({
            ...item,
            value: item.name,
            id: item.id,
            key: item.id,
          })}
        />
      </Collapse>
      <Collapse in={!hasIssueDetails && !hasIssueType}>
        <Divider mt='lg' label='or' labelPosition='center' />
      </Collapse>
      <Collapse in={!hasIssueType}>
        <TextInput
          disabled={hasIssueType}
          value={ticket.newIssueTitle}
          onChange={(e) => setTicket({ ...ticket, newIssueTitle: e.target.value })}
          mt='sm'
          label='Create new fixture issue type'
          placeholder='Fixture issue title'
          onBlur={() => {
            if (hasIssueType) {
              setTicket({ ...ticket, issueTypeId: null, issueType: null });
            }
            setLogTitle(ticket.newIssueTitle);
          }}
        />
        <Textarea
          disabled={hasIssueType}
          value={ticket.newIssueDescription}
          onChange={(e) => setTicket({ ...ticket, newIssueDescription: e.target.value })}
          mt='sm'
          label='Issue Description'
          placeholder='Issue description'
          onBlur={() => {
            if (hasIssueType) {
              setTicket({ ...ticket, issueTypeId: null, issueType: null });
            }

            setLogDescription(ticket.newIssueDescription);
          }}
        ></Textarea>
        <Group mt='md' position='right'>
          <Button
            size='xs'
            disabled={ticket.newIssueTitle === '' && ticket.newIssueDescription === ''}
            onClick={() =>
              setTicket({
                ...ticket,
                newIssueTitle: '',
                newIssueDescription: '',
              })
            }
          >
            Clear
          </Button>
        </Group>
      </Collapse>
    </Collapse>
  );

  // Memoized Position Search
  const positionSearch = useMemo(() => {
    return (
      <>
        <Group position='right' mt='xs' mb='xs'>
          <Switch
            label='Exact'
            checked={positionSearchExact}
            onChange={(e) => setPositionSearchExact(e.currentTarget.checked)}
          ></Switch>
        </Group>
        <FullTextSearch
          model={{
            service: 'position-search',
            injectGetQuery: ['venueId'],
          }}
          getService='positions'
          initialGetFormatter={(data) => {
            return getPositionCompositeName(data, '/', true);
          }}
          exact={positionSearchExact}
          placeholder='(Unassigned)'
          value={positionId}
          onChange={setPositionId}
          customItem={forwardRef(({ data, ...others }, ref) => {
            return (
              <div ref={ref} {...others}>
                <PositionBadgeRenderer positionId={others.id} />
              </div>
            );
          })}
          getValueMap={(item) => ({
            ...item,
            value: `${
              item.parentId
                ? `${item.parentName}${item.shortname ? ` (${item.parentShortname}) / ` : ' / '}`
                : ' '
            }${item.name}`,
            id: item.id,
            key: item.id,
          })}
          triggerReset={triggerReset}
          setTriggerReset={setTriggerReset}
        />
      </>
    );
  }, [positionId, triggerReset, positionSearchExact]);

  const statusList = [
    { label: 'Good', value: '30' },
    { label: 'Faulty', value: '20' },
    { label: 'Unusable', value: '10' },
    { label: 'Unknown', value: '0' },
  ];
  if (!initialFixture) {
    statusList.unshift({ label: 'Keep Status', value: '-1' });
  }

  return (
    <>
      {!initialFixture && (
        <>
          <Divider mt='md' mb='xs' />
          <DataAutocomplete
            size='xl'
            model={{
              service: 'fixtures',
              injectGetQuery: ['orgId', 'venueId'],
            }}
            label='Fixture'
            placeholder='Select fixture to log'
            value={fixtureId}
            onChange={setFixtureId}
            mt='xs'
            mb='xs'
            matchField={'identifier'}
            secondaryMatchField={'nickname'}
            getValueMap={(item) => ({
              id: item.id,
              value: getFixtureCompositeName(item),
              data: item,
            })}
            customItem={forwardRef(({ data, ...others }, ref) => {
              return (
                <div ref={ref} {...others}>
                  <Group nowrap>
                    <StatusBadgeRenderer statusId={data.status} chip />
                    <Text>
                      {data.nickname ? `${data.identifier} (${data.nickname})` : data.identifier}
                    </Text>
                  </Group>
                </div>
              );
            })}
          />
        </>
      )}

      <Box sx={{ position: 'relative' }}>
        {initialFixture && <Title>{fixture ? getFixtureCompositeName(fixture) : ''}</Title>}

        {!fixture && <Overlay sx={{ margin: '0px -4px' }} color='#0e0e0e' opacity={0.5} />}
        <Group position='apart'>
          <Text color='dimmed'>{fixtureType?.name || '(select fixture)'}</Text>
          {fixture?.positionId ? (
            <PositionBadgeRenderer positionId={fixture?.positionId} />
          ) : (
            <Text color='dimmed' size='sm'>
              {fixture ? 'Unassigned' : ''}
            </Text>
          )}
        </Group>

        <Divider mt='md' mb='md' />
        {!initialFixture && (
          <Fragment>
            <Group position='center' mt='xl'>
              <Button
                disabled={!formIsValid()}
                onClick={() => {
                  setSuppressClose(false);
                  handleSubmit();
                }}
                color={initialFixture ? 'blue' : 'gray'}
              >
                {initialFixture ? 'Submit' : 'Submit and Close'}
              </Button>
              {!initialFixture && (
                <Button
                  disabled={!formIsValid()}
                  onClick={() => {
                    setSuppressClose(true);
                    handleSubmit();
                  }}
                >
                  Submit and Continue
                </Button>
              )}
            </Group>

            {error && (
              <Text mt='xl' align='center' color={'red'}>
                {error}
              </Text>
            )}
            <Divider mt='md' mb='md' />
          </Fragment>
        )}

        <Text size='sm' mb='0' weight='500'>
          Log Type
        </Text>
        <SegmentedControl
          fullWidth
          size='sm'
          label='Log Type'
          value={logType}
          onChange={(data) => {
            setLogType(data);
          }}
          data={[
            { label: 'Info', value: '1' },
            { label: 'Service', value: '2' },
            { label: 'Problem', value: '3' },
            { label: 'Move', value: '4' },
          ]}
        />

        <Box
          sx={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            border: `1px solid ${
              theme.colorScheme === 'dark'
                ? theme.colors.dark[positionId && positionId !== fixture?.positionId ? 4 : 7]
                : theme.colors.gray[3]
            }`,
            borderRadius: 6,
            margin: '12px -12px 12px -12px',
            padding: '12px',
            transition: 'border-color 0.3s',
          })}
        >
          <Text size='sm' mb='0' weight='500'>
            Position
          </Text>
          {!initialFixture && (
            <SegmentedControl
              mb='sm'
              fullWidth
              size='sm'
              data={[
                { label: 'Keep Position', value: '-1' },
                { label: 'Change Position', value: '1' },
              ]}
              onChange={setPositionContext}
              value={positionContext}
            />
          )}
          {(initialFixture || positionContext > 0) && positionSearch}
          {/* Fixture Positions */}
          <Collapse in={positionId !== fixture?.positionId && fixture?.positionId !== null}>
            <DataAutocomplete
              model={{
                service: 'fixtures',
                injectGetQuery: ['orgId', 'venueId'],
              }}
              additionalQuery={{
                typeId: fixture?.typeId,
                id: { $ne: fixture?.id },
              }}
              label='Replace With'
              placeholder='(Do not replace)'
              value={repFixtureId}
              onChange={setRepFixtureId}
              mt='xs'
              matchField={'identifier'}
              secondaryMatchField={'nickname'}
              getValueMap={(item) => ({
                id: item.id,
                value: getFixtureCompositeName(item),
                data: item,
              })}
              reset={triggerReplaceReset}
              setReset={setTriggerReplaceReset}
              customItem={forwardRef(({ data, ...others }, ref) => {
                return (
                  <div ref={ref} {...others}>
                    <Group nowrap>
                      <StatusBadgeRenderer statusId={data.status} chip />
                      <Text>
                        {data.nickname ? `${data.identifier} (${data.nickname})` : data.identifier}{' '}
                      </Text>
                      {data.positionId ? (
                        <PositionBadgeRenderer positionId={data.positionId} />
                      ) : (
                        <Text
                          size='xs'
                          color={'dimmed'}
                          weight={300}
                          style={{ fontStyle: 'italic' }}
                        >
                          -unassigned-
                        </Text>
                      )}
                    </Group>
                  </div>
                );
              })}
            />
          </Collapse>
        </Box>

        <Text size='sm' mb='0' weight='500'>
          Status
        </Text>
        <SegmentedControl
          fullWidth
          size='sm'
          value={status}
          onChange={setStatus}
          data={statusList}
        />

        <Box
          sx={(theme) => ({
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            border: `1px solid ${
              theme.colorScheme === 'dark'
                ? theme.colors.dark[ticket.context === 'none' ? 7 : 4]
                : theme.colors.gray[3]
            }`,
            borderRadius: 6,
            margin: '12px -12px 12px -12px',
            padding: '12px',
            // animate the border color time to 1 second
            transition: 'border-color 0.3s',
          })}
        >
          <Text size='sm' mb='0' weight='500'>
            Ticket
          </Text>
          <SegmentedControl
            fullWidth
            value={ticket.context}
            onChange={(val) => setTicket({ ...ticket, context: val })}
            data={[
              { label: 'None', value: 'none' },
              { label: 'Ticket', value: 'ticket' },
              { label: 'New Ticket', value: 'new' },
            ]}
          />
          {OpenTicket}
          {CreateTicket}
        </Box>

        <Text size='sm' mb='0' weight='500'>
          Log Details
        </Text>
        <TextInput
          mt='sm'
          placeholder='Log title'
          value={logTitle}
          onChange={(e) => setLogTitle(e.target.value)}
          onBlur={() => {
            if (logTitle && logTitlePristine) {
              setLogTitlePristine(false);
            }
          }}
        />
        <Textarea
          mt='sm'
          placeholder='Log description'
          value={logDescription}
          onChange={(e) => setLogDescription(e.target.value)}
        ></Textarea>

        <Text size='sm' mb='0' mt='md' weight='500'>
          Service Tags
        </Text>
        <Chip.Group mt='sm' position='center' multiple={true} value={tags} onChange={setTags}>
          {serviceTags?.data &&
            serviceTags.data.map((tag) => (
              <Chip key={tag.id} value={tag.id}>
                {tag.name}
              </Chip>
            ))}
        </Chip.Group>

        {venue.isKiosk && (
          <Group mt='xl'>
            <Text size='sm' mb='0' weight='500'>
              Log As User
            </Text>
            <Chip.Group
              mt='0'
              required
              position='center'
              multiple={false}
              value={user}
              onChange={setUser}
            >
              {venueUsers &&
                venueUsers.data &&
                venueUsers.data.map((u) => <Chip value={u.userId}>{u.username}</Chip>)}
            </Chip.Group>
          </Group>
        )}

        <Group position='center' mt='xl'>
          <Button
            disabled={!formIsValid()}
            onClick={() => {
              setSuppressClose(false);
              handleSubmit();
            }}
            color={initialFixture ? 'blue' : 'gray'}
          >
            {initialFixture ? 'Submit' : 'Submit and Close'}
          </Button>
          {!initialFixture && (
            <Button
              disabled={!formIsValid()}
              onClick={() => {
                setSuppressClose(true);
                handleSubmit();
              }}
            >
              Submit and Continue
            </Button>
          )}
        </Group>
        {error && (
          <Text mt='xl' align='center' color={'red'}>
            {error}
          </Text>
        )}
      </Box>
    </>
  );
}

export default LogForm;
